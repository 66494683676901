.vote-area {
  height:100%;
  width:100%;
  position:absolute;
  background-size: cover;
  position: relative;
  .content {
    height:100%;
    align-items:center;
    justify-content:center;
    display: flex;
    padding:20px;
    flex-direction: column;
    .qrcode-section{
      border-radius:10px;
      align-items: center;
      @media (min-width: 700px) { 
        display: flex;
        flex-direction: row;
      }
      .info-text{
        max-width: 500px;
        padding: 20px 30px;
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(4px);
        @media (min-width: 700px) { 
          height:100%;
          border-top-left-radius:20px;
          border-bottom-left-radius:20px;
          padding: 30px ;
        }
        .title{
          font-size: 32px;
          color: #A700D4;
          line-height:40px;
          text-align: center;
          font-weight: 600;
          @media (min-width: 700px) { 
            font-size: 48px;
            line-height: 66px;
          }
        }
        .description{
          font-size: 18px;
          color:#43008A;
          line-height: 24px;
          text-align: center;
          @media (min-width: 700px) { 
            font-size: 24px;
            line-height: 50px;
          }
        }

        .description-middle{
          font-weight: 600;
          @media (min-width: 700px) { 
            font-size: 24px;
            line-height: 50px;
          }
        }
        .list-btn{
          width: 100%;
          height:50px;
          border-radius: 100px;
          margin-top: 10px;
          border: none;
          color: white;
          position: relative;
          align-items: center;
          justify-content: center;
          display: flex;
          font-size: 20px;
          @media (min-width: 700px) { 
            margin-top: 15px;
          }
          .btn-namber{
            position: absolute;
            left: 10px;
            width: 35px;
            height: 35px;
            border-radius:30px;
            background: #0000003f;
            position: absolute;
            align-items: center;
            justify-content: center;
            display: flex;
          }
        }
        .list-btn-yes{
          background:#5086FF;
        }
        .list-btn-no{
          background:#FEB300;
        }
        .list-btn-abstain{
          background:#F3693A;
        }
      }
      .qr-code{
        background:white;
        padding: 30px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 320px;
        @media (min-width: 700px) { 
          height:100%;
          border-top-right-radius:20px;
          border-bottom-right-radius:20px;
        }
      }
    }
    .bottom-logo{
      display:flex;
      flex-direction:column;
      align-items:center;
      position:absolute;
      bottom:20px;
      .logo-text{
        margin-top: 5px;
      }
    }
  }
}