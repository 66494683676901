.device-area {
  height:100%;
  width:100%;
  position:absolute;
  background-size: cover;
  .content {
    position: relative;
    padding: 15px 20px;
  }
  .content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 16px; 
    border: 4px solid transparent;
    background: linear-gradient(180deg, #47A5F1 , #101A5C) border-box;
    -webkit-mask:
      linear-gradient(#fff 0 0) padding-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

}