/*------- 6. Product style  --------*/
.delivery-area {
  height:100%;
  width:100%;
  position:absolute;
  .content {
    height:100%;
    display:flex;
    flex-direction: row;
    align-items:center;
    justify-content:space-between;
    .left-section{
      display: none;
      @media (min-width: 900px) {
        display:flex;
        height:100%;
        flex:1;
        background-size: cover;
      }
    } 
    .qrCode-section  {
      height:100%;
      background: #1E2724;
      flex:1;
      display:flex;
      justify-content:center;
      align-items:center;
      padding:30px; 
      flex-direction:column; 
      .title{
        text-align: center;
        font-size:32px;
        font-weight:600;
        color:#DFD284;
      }
      .description{
        text-align: center;
        font-size:24px;
        font-weight:400;
        line-height: 33px;
        color:white;
        max-width: 330px;
        margin-top: 20px;
      }
      .qrCodeCover{
        text-align: center;
        background: white;
        padding: 30px;
        margin-top:20px;
        border-radius:10px;
        .qrCode{
          width: 260px;
          height: 260px;
          margin: auto;
          border-width: 4px;
          border-color: #fff;
          cursor: pointer;
        }
      }
      .bottom-logo{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute; 
        bottom:20px;
        color:#fff
      }
    } 
  }
}