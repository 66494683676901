.age {
  .btn-custom{
    background: #4a56a7; 
    box-shadow: 0px 5px 10px rgba(30,  61, 116, 0.25);
    border-radius: 100px;
    color: white;
    padding: 12px 42px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 232px;
    &:hover {
      background: #3d4ba2;
      color: white;
    }
  }
}
canvas{
  position: absolute;
  width: 328px;
  height: 214px;
}
.gaming-age-area{
  height:100%;
  width:100%;
  position:absolute;
  background-size: cover;
  position: relative;
  color:white;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #000017;
  .content {
    height:100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    padding: 20px;
    .qr-code-section {
      position: relative;
    }
    .qr-code-section::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 16px; 
      border: 4px solid transparent;
      background: linear-gradient(180deg, #FEE0AE , #813C1B) border-box;
      -webkit-mask:
        linear-gradient(#fff 0 0) padding-box, 
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }
    .qr-code-section{
      margin-top: 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 40px 90px;
      background: rgba(0,0,0,0.5);
      border-radius: 16px;
      backdrop-filter: blur(4px);
      .description{
        font-size: 24px;
        font-weight: 400;
        max-width: 300px;
        text-align: center;
        line-height: initial;
      }
      .result-screen-text{
        margin-top: 20px;
        text-align: center;
      }
      .qrcode-section{
        padding:30px;
        background:white;
        border-radius:10px;
        margin-top: 20px;
      }
    }
    .bottom-logo{
      display:flex;
      flex-direction:column;
      align-items:center;
      position:absolute;
      bottom:100px;
      .logo-text{
        margin-top: 5px;
      }
    }
  }
  .result-content {
    height:100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    .result-modal-body{
      padding: 24px;
      background: white;
      border-radius: 16px;
      margin-top: 100px;
      width: 448px;
    }
    .result-failue-modal-body{
      margin-top: 150px;
      width: 500px;
    }
    .description{
      font-size: 24px;
      font-weight: 400;
      margin-top: 20px;
      max-width: 300px;
      text-align: center;
      line-height: initial;
    }
    .result-screen-text{
      margin-top: 20px;
      text-align: center;
    }
    .qrcode-section{
      padding:30px;
      background:white;
      border-radius:10px;
      margin-top: 20px;
    }
  }
  .bottom-logo{
    display:flex;
    flex-direction:column;
    align-items:center;
    position:absolute;
    bottom:100px;
    .logo-text{
      margin-top: 5px;
    }
  }
}
.result-modal-submit-btn{
  height: 48px;
  margin-top: 20px;
  background: #013A94;
  border: none;
  color: white;
  border-radius: 50px;
  width: 65%;
  &:hover {
    cursor: pointer;
    background: #022763;
  }
}
.gamingAge-result-modal .modal-dialog {
  max-width: 600px !important;
}