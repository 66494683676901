/*----- 20. Cart page  -----*/

.shop-cart-main-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
  .header-backgrund{
    background: #14192B
  }
  .mt-100{
    margin-top: 100px;
  }
  .header{
    display: flex;
    color: #FFFFFF;
    height: 163px;
    padding: 15px 0 15px 0;
    .logo{
      height: 64px;
      align-self: center;
      @media #{$xs-layout} {
        height: 40px;
      }
    }
    .header-content{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 15px;
      flex:1;
      @media (min-width: 992px) { 
        margin-left: 150px;
      }
      .top-row{
        display: flex;
        flex-direction: row;
        font-weight: 400;
        .line{
          width: 1px;
          height: 22px;
          margin: 0 10px 0 10px;
          border-left: 1px solid #4F5360;
        }
        @media #{$xs-layout} {
          font-size: 12px;
        }
      }
      .middle-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .cart{
          display: flex;
          .cart-text{
            margin-left: 10px;
          }
        }
        .search-container {
          display: flex;
          align-items: center;
          width: 350px;
          margin-right: 10px;
          border-radius: 8px;
          @media #{$xs-layout} {
            width: 100%;
          }
        }
        .search-input {
          padding: 10px 30px 10px 10px;
          border: 1px solid #676D7F;
          background: no-repeat; 
          background-size: 20px 20px;
          background-position: 95% center;
          color: #102B26;
        }
        .search-input::placeholder {
          color: #999;
        }
        .search-input:focus {
          border-color: #007bff;
        }
        
      }
      .bottom-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-weight: 600;
        .line{
          width:2px;
          height: 22px;
          margin: 0 10px 0 10px;
          border-left: 2px solid #4F5360;
        }
        .menu-icon-cover{
          align-self: flex-end
        }
        .menu-icon{
          width: 30px;
        }
        // @media #{$xs-layout} {
        //   display: none;
        // }
        @media (max-width: 992px) { 
          display: none;
        }
      }
      .bottom-menu{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        position: relative;
        .menu-icon{
          width: 30px;
          height: 30px;
          cursor: pointer;
        }
        @media (min-width: 992px) { 
          display: none;
        }
        .sub-menu{
          position: absolute;
          right: 0;
          background: #54565d;
          top: 30px;
          padding: 10px;
        }
      }
      // .bottom-row-no-xs{
      //   display: flex;
      //   flex-direction: row;
      //   justify-content: space-between;
      //   .line{
      //     width: 1px;
      //     height: 22px;
      //     margin: 0 10px 0 10px;
      //     border-left: 1px solid #4F5360;
      //   }
      //   @media #{$lg-layout} {
      //     display: none;
      //   }
      //   @media #{$md-layout} {
      //     display: none;
      //   }
      // }
    }
  }
  .btn-in-shop{
    height: 48px;
    margin-top: 40px;
    background: #EC5C2A;
    border: none;
    color: white;
    width: 100%;
    &:hover {
      cursor: pointer;
      background: #d54211;
    }
  }
  .result-modal{
    width:360px;
    .btn-in-shop{
      height: 48px;
      margin-top: 40px;
      background: #EC5C2A;
      border: none;
      color: white;
      width: 100%;
      &:hover {
        cursor: pointer;
        background: #d54211;
      }
    }
  }
  .result-total {
    padding: 15px 0px 15px 0px;
    @media (min-width: 992px) { 
      padding: 15px 0px 50px 30px;
    }
    .selector-group{
      .selector-wrap{
        display: flex;
        justify-content: space-between;
        .label{
          font-size: 16px;
          font-weight: 600;
        }
        .select{
          color: #EC5C2A;
          font-weight: 300;
          font-size: 16px;
        }
        .arrow{
          font-size: 16px;
        }
      }
    }
    .price-group{
      margin-top: 40px;
      .price-text-line{
        margin: 15px 0 15px 0;
        font-size: 16px;
      }
    }
    @media #{$lg-layout} {
      padding: 45px 18px 50px;
    }
    @media #{$md-layout} {
      margin-top: 30px;
    }
    .title-wrap {
      position: relative;
      &::before {
        position: absolute;
        z-index: 1;
        top: 10px;
        left: 0;
  
        width: 100%;
        height: 1px;
  
        content: "";
        transition: all 0.4s ease 0s;
  
        background-color: #e3e1e1;
      }
      h4.cart-bottom-title {
        font-size: 18px;
        font-weight: 500;
  
        display: inline-block;
  
        margin: 0;
        padding-right: 18px;
      }
      .section-bg-gary-cart {
        position: relative;
        z-index: 9;
  
        background-color: #f9f9f9;
      }
    }
    h5 {
      font-size: 14px;
  
      margin: 36px 0 27px;
      span {
        font-size: 18px;
        font-weight: 500;
  
        float: right;
      }
    }
    .total-shipping {
      margin: 0 0 27px;
      padding: 28px 0;
      border-top: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;
      h5 {
        font-size: 14px;
        margin: 0;
      }
      ul {
        padding: 19px 0 0 0;
        li {
          margin: 0 0 6px;
          list-style: outside none none;
          color: #242424;
          &:last-child {
            margin: 0 0 0;
          }
          input {
            position: relative;
            top: 2px;
            width: 13px;
            height: 13px;
            margin-right: 10px;
            padding: 0;
            cursor: pointer;
            color: #626262;
            border: 1px solid #d7d7d7;
            border-radius: 5px !important;
            background: #e9e9e9 none repeat scroll 0 0;
          }
          span {
            float: right;
          }
        }
      }
    }
    h4.total-val-title {
      border-top: 1px solid #E5E5E5;
      font-size: 20px;
      font-weight: 500;
      padding: 15px 0 15px;
      span {
        float: right;
      }
      .total-val{
        color:#EC5C2A
      }
    }
    a {
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      display: block;
      padding: 18px 10px 17px;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      border-radius: 50px;
      background-color: $theme-color;
      &:hover {
        background-color: #333;
      }
    }
  }
  
  h3.cart-page-title {
    font-size: 20px;
    font-weight: 500;
    margin: 30px 0 15px;
  }
  .to-product-list{
    color:#666666;
    font-size: 12px;
    margin: 15px 0 15px 0;
  }

  .shop-cart-table-content {
    table {
      border-bottom: 1px solid #ebebeb;
      thead > tr {
        border: 1px solid #ebebeb;
        background-color: #f9f9f9;
        th {
          font-size: 14px;
          font-weight: 500;

          padding: 21px 45px 22px;

          text-align: center;
          vertical-align: middle;
          white-space: nowrap;
          text-transform: uppercase;

          color: #333;
          border-top: medium none;
          @media #{$lg-layout} {
            padding: 21px 35px 22px;
          }
          @media #{$xs-layout} {
            padding: 21px 20px 22px;
          }
        }
        .empty-th{
          @media #{$xs-layout} {
            display: none;
          }
        }
      }
      thead >tr{
        background: #E7E7E9;
        border: none;
        th {
          padding: 10px 0px 10px 30px;
          text-transform: none;
          @media #{$xs-layout} {
            padding: 10px 10px 10px 10px;
          }
        }
        th.product-quantity {
          text-align: left;
        }
        th.product-price {
          text-align: left;
        }
      }
      tbody > tr {
        border-bottom: 1px solid #ebebeb;
        td.product-thumbnail {
          width: 150px;
        }
        td.product-name {
          width: 435px;
          text-align: left;
          a {
            font-size: 15px;
            color: #333;
            &:hover {
              color: $theme-color;
            }
          }
          @media #{$xs-layout} {
            display: none;
          }
        }
        td.product-price-cart {
          width: 435px;
          span {
            font-weight: 500;
            color: #333;
            &.old {
              margin-right: 10px;
              text-decoration: line-through;
              color: #8e8e8e;
            }
          }
        }
        td.product-subtotal {
          text-align: left;
          font-weight: 500;
          color: #333;
          @media #{$xs-layout} {
            width: 450px;;
          }
        }
        td.product-quantity {
          text-align: left;
          .cart-plus-minus {
            display: flex;
            .qtybutton {
              font-size: 16px;
              float: inherit;
              width: 32px;
              height:32px;
              // border-radius: 8px;
              margin: 0;
              cursor: pointer;
              transition: all 0.3s ease 0s;
              text-align: center;
              color: #333;
              border: none;
            }
            .dec.qtybutton {
              background:#E5E5E5;
              &:hover{
                background:#d7cfcf;
              }
            }
            .inc.qtybutton {
              background:#FFE3DA;
              &:hover{
                background:#f9cbbb;
              }
            }
            .cart-plus-minus-box {
              height: 32px;
              font-size: 14px;
              float: left;
              width: 32px;
              margin: 0;
              padding: 0;
              text-align: center;
              color: #333;
              border: none;
              background: transparent none repeat scroll 0 0;
            }
          }
        }
        .product-remove {
          text-align: right;
          margin-top: 10px;
          a,
          button {
            font-size: 17px;
            color: #666;
            border: none;
            background: none;
            &:hover {
              color: $theme-color;
            }
          }
          @media #{$xs-layout} {
            display: none;
          }
        }
        td.product-wishlist-cart > a,
        td.product-wishlist-cart > button {
          font-size: 13px;
          font-weight: 500;
          line-height: 1.2;
          display: block;
          margin: 0 auto;
          padding: 10px 15px;
          text-transform: uppercase;
          color: #fff;
          border: none;
          border-radius: 50px;
          background: none;
          background-color: $theme-color;
          &:hover,
          &.active {
            background-color: #333;
          }
          &:disabled {
            cursor: not-allowed;
          }
        }
        td {
          font-size: 15px;
          padding: 30px 0 30px 30px;
          text-align: center;
          color: #333;
          @media #{$xs-layout} {
            padding: 30px 10px 30px 10px;
          }
        }
      }
    }
  }

  .cart-shiping-update-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 30px 0 55px;
    @media #{$xs-layout} {
      display: block;
      padding: 30px 0 15px;
    }
    .cart-shiping-update > a,
    .cart-clear > button,
    .cart-clear > a {
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      display: inline-block;
      padding: 18px 63px 17px;
      text-transform: uppercase;
      color: #363f4d;
      border-radius: 50px;
      background-color: #f2f2f2;
      @media #{$md-layout} {
        font-size: 13px;

        padding: 18px 25px 17px;
      }
      @media #{$xs-layout} {
        margin: 0 0 15px;
        padding: 18px 40px 17px;
      }
      &:hover {
        color: #fff;
        background-color: $theme-color;
      }
    }
    .cart-clear > button {
      margin-right: 27px;
      cursor: pointer;
      transition: all 0.3s ease 0s;
      border: medium none;
      &:last-child {
        margin-right: 0;
      }
      @media #{$md-layout} {
        margin-right: 15px;
      }
    }
  }

  .cart-tax,
  .discount-code-wrapper {
    padding: 45px 30px 50px;

    border: 1px solid #ebebeb;
    border-radius: 5px;
    background-color: #f9f9f9;
    @media #{$lg-layout} {
      padding: 45px 18px 50px;
    }
    @media #{$xs-layout} {
      padding: 45px 18px 50px;
    }
    .title-wrap {
      position: relative;
      &::before {
        position: absolute;
        z-index: 1;
        top: 10px;
        left: 0;
        width: 100%;
        height: 1px;
        content: "";
        transition: all 0.4s ease 0s;
        background-color: #e3e1e1;
      }
      h4.cart-bottom-title {
        font-size: 18px;
        font-weight: 500;
        display: inline-block;
        margin: 0;
        padding-right: 18px;
        @media #{$lg-layout} {
          font-size: 16px;
        }
      }
      .section-bg-gray {
        position: relative;
        z-index: 99;
        background-color: #f8f9f9;
      }
    }
    .tax-wrapper {
      margin-top: 22px;
      p {
        margin: 0;
      }
      .tax-select-wrapper {
        margin: 5px 0 0;
        .tax-select {
          margin: 0 0 26px;
          label {
            font-size: 14px;
            margin: 0 0 5px;
            color: #242424;
          }
          select {
            font-size: 12px;
            width: 100%;
            height: 40px;
            padding: 0 50px 0 15px;
            cursor: pointer;
            color: #242424;
  //background: #fff url("../../assets/img/icon-img/cart.png") no-repeat scroll right 18px center;
            border: 1px solid #ebebeb;
            box-shadow: none;
            -webkit-appearance: none;
              -moz-appearance: none;
          }
          input {
            height: 40px;
            border: 1px solid #ebebeb;
            background: #fff none repeat scroll 0 0;
          }
        }
        button.cart-btn-2 {
          font-size: 14px;
          font-weight: 500;
          padding: 13px 42px 12px;
          cursor: pointer;
          transition: all 0.3s ease 0s;
          text-transform: uppercase;
          color: #fff;
          border: medium none;
          border-radius: 50px;
          background-color: $theme-color;
          &:hover {
            background-color: #333;
          }
        }
      }
    }
    .discount-code {
      margin: 21px 0 0;
      p {
        margin: 0 0 15px;
      }
      form {
        input {
          height: 40px;
          margin-bottom: 30px;
          padding-left: 10px;
          border: 1px solid #ebebeb;
          background: #fff;
        }
        button.cart-btn-2 {
          font-size: 14px;
          font-weight: 500;
          padding: 13px 42px 12px;
          cursor: pointer;
          transition: all 0.3s ease 0s;
          text-transform: uppercase;
          color: #fff;
          border: medium none;
          border-radius: 50px;
          background-color: $theme-color;
          &:hover {
            background-color: #333;
          }
        }
      }
    }
  }

  .discount-code-wrapper {
    @media #{$xs-layout} {
      margin: 30px 0;
    }
  }

  .cart-item-variation {
    margin-top: 10px;
    span {
      display: block;
    }
  }
}
.shop-verify-modal{
  .modal-dialog{
    width: 480px;
  }
  .modal-body{
    padding: 35px 35px;
  }
  .qr-modal-title{
    font-size: 20px;
    text-align: center;
  }
  .qr-modal-text{
    font-size: 16px;
    color: #333;
    text-align: center;
  }
}
.shop-success-modal{
  .modal-dialog{
    width: 450px !important;
  }
  .modal-body{
    padding: 35px 35px;
  }
}

.result-modal-shop{
  .btn-in-shop{
    height: 48px;
    background: #EC5C2A;
    border: none;
    border-radius: 8px;
    color: white;
    width: 90%;
    &:hover {
      cursor: pointer;
      background: #d54211;
    }
  }
}