.bank-area {
  li {
    list-style-type: none;
    padding-left: 20px;
    font-size:16px;
    margin-top: 5px;
  }
  li:before {
    content: ""; color: #40bbfd;
    float: left;
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
    width: 20px;
    margin-left: -20px;
    font-size:24px;
  }
  height:100%;
  width:100%;
  position:absolute;
  background-size: cover;
  position: relative;
  color:white;
  .content {
    height:100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    padding:20px;
    .top-logo{
      display:flex;
      align-items:center;
      position:absolute;
      top:20px;
      left:20px;
      .logo-text{
        margin-left: 10px;
        font-size: 32px;
        font-weight: 500;
      }
    }
    .description{
      font-size: 32px;
      // font-weight: 400;
      text-align: center;
      line-height: initial;
    }
    @media only screen and (max-width: 800px) {
      .description {
        width:80%;
        font-size: 24px;
      }
    }
    @media only screen and (max-width: 600px) {
      .description {
        width:100%;
        font-size: 24px;
      }
    }
    .result-screen-text{
      margin-top: 20px;
      text-align: center;
    }
    .qrcode-section{
      padding:30px;
      background:white;
      border-radius:10px;
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      .info-text{
        margin-left: 20px;
        color:black;
        .title{
          font-size: 24px;
          margin-bottom: 20px;
        }
        @media only screen and (max-width: 800px) {
          .title {
            margin-top: 10px;
          }
        }
        .info{
          font-size: 16px;

        }
      }
    }
    
    @media only screen and (max-width: 800px) {
      .qrcode-section {
        flex-direction: column;
        margin-left: 0px;
        width:80%;
        align-items: center;
        margin-top: 10px;
      }
    }
    @media only screen and (max-width: 600px) {
      .qrcode-section {
        flex-direction: column;
        margin-left: 0px;
        width:100%;
        align-items: center;
        margin-top: 10px;
      }
    }
    .info-section{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding:20px;
      background:white;
      border-radius:10px;
      width:400px;
      .info-title{
        color:black;
        font-size: 24px;
        text-align: center;
        margin-bottom: 10px;
      }
      .input-form{
        width: 100%;
        margin-top: 15px;
        .input-label{
          color:#777777;
          font-size: 14px;
        }
        .input-text{
          margin-top: 5px;
          border-radius: 10px;
          background: #ecefff;
        }
        
      }
      .btn-submit {
        border-radius: 50em;
        padding:12px;
        border: 2px solid transparent;
        background:#40bbfd;
        margin-top: 20px;
        color:white;
        width:200px;
        &:hover{
          background:#2badf3;
        }
      }
    }
    @media only screen and (max-width: 800px) {
      .info-section {
        width:60%;
      }
    }
    @media only screen and (max-width: 600px) {
      .info-section {
        width:100%;
      }
    }
    .btn-gradient {
      border-radius: 50em;
      padding:12px;
      border: 2px solid transparent;
      background:white;
      margin-top: 20px;
    }
    .bottom-logo{
      display:flex;
      flex-direction:column;
      align-items:center;
      position:absolute;
      bottom:20px;
      .logo-text{
        margin-top: 5px;
      }
    }
  }
  

}