/*------- 6. Product style  --------*/
.hotel-area {
  height:100%;
  width:100%;
  position:absolute;
  background-size: cover;
  position: relative;
  .content {
    height:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding-left: 200px;
    .empry{
      flex:1px;
      height:50%;
      border-top:3px solid #dcbfa6;
      border-bottom:3px solid #dcbfa6;
    }
    .empry-m{
      flex:1px;
      height:80%;
      border-top:3px solid #dcbfa6;
      border-bottom:3px solid #dcbfa6;
    }
    .left-section {
      background:#37323289;
      backdrop-filter:blur(30px);
      align-items: center;
      justify-content: center;
      display: flex;
      padding-left:30px;
      height : 100%;
      color:white;
      max-width: 400px;
      .left-section-content{
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        height:50%;
        border-left:3px solid #dcbfa6;
        border-right: none;
        .top-border{
          display:flex; 
          flex:1;
          width:100%;
          .border1 {
            width:25%;
            border-top: 3px solid #dcbfa6;
          }
          .border2 {
            flex:1
          }
        }
        .bottom-border{
          flex:1;
          width:100%;
          border-bottom: 3px solid #dcbfa6;
        }
        .desscription{
          color:white;
          margin-top:50px;
          font-size:32px;
          line-height: normal;
          text-align:center;
          padding-left:20px;
          padding-right:20px;          
        }
        .bottom-logo{
          display:flex;
          flex-direction:column;
          align-items:center;
          position:absolute;
          bottom:20px;
        }
      }
    }
    .right-section {
      display: flex;
      flex-direction: column;
      border-radius:16px;
      align-items:center;
      justify-content: center;
      height : 100%;
      .right-section-border{
        justify-content: center;
        display: flex;
        flex-direction: column;
        height:50%;
        border-top:3px solid #dcbfa6;
        border-bottom:3px solid #dcbfa6;
        padding:20px;
        padding-right:200px;
        .right-section-content{
          background:#fbfdfa89;
          backdrop-filter:blur(30px);
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;
          padding:40px;
          border-radius: 16px;
          min-width: 400px;
          .title{
            font-size:24px;
            text-align: center;
          }
          .btn-gradient {
            background: linear-gradient(#373232, #373232) padding-box,
                        linear-gradient(to left, #e7d7c7, #8d8480) border-box;
            border-radius: 50em;
            border: 2px solid transparent;
          }
          .btn-gradient {
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 1em;
            color: white;
            padding: 0.5rem 1rem;
            cursor: pointer;
            margin-top:15px;
          }
          .desscription{
            margin-top:15px;
            text-align: center;
          }
        }
      }
    }
    .qrcode-section{
      padding:30px;
      background:white;
      border-radius:10px;
    }

    .left-section-mobile {
      background:#37323289;
      backdrop-filter:blur(30px);
      align-items: center;
      justify-content: center;
      display: flex;
      padding-left:30px;
      height : 100%;
      color:white;
      max-width: 400px;
      .left-section-content{
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        height:80%;
        border-left:3px solid #dcbfa6;
        border-right: none;
        .top-border{
          display:flex; 
          flex:1;
          width:100%;
          .border1 {
            width:25%;
            border-top: 3px solid #dcbfa6;
          }
          .border2 {
            flex:1
          }
        }
        .bottom-border{
          flex:1;
          width:100%;
          border-bottom: 3px solid #dcbfa6;
        }
        .desscription{
          color:white;
          // margin-top:10px;
          font-size:30px;
          line-height: normal;
          text-align:center;
          padding-left:20px;
          padding-right:20px;          
        }
        .bottom-logo{
          display:flex;
          flex-direction:column;
          align-items:center;
          position:absolute;
          bottom:20px;
        }
        .right-section {
          // display: flex;
          // flex-direction: column;
          // border-radius:16px;
          // align-items:center;
          // justify-content: center;
          padding:20px;
          height:50%;
          margin-top: 20px;
          color:#000;
          .right-section-content{
            background:#fbfdfa89;
            backdrop-filter:blur(30px);
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            padding:30px;
            border-radius: 16px;
            width: 100%;
            .title{
              font-size:20px;
              text-align: center;
            }
            .btn-gradient {
              background: linear-gradient(#373232, #373232) padding-box,
                          linear-gradient(to left, #e7d7c7, #8d8480) border-box;
              border-radius: 50em;
              border: 2px solid transparent;
            }
            .btn-gradient {
              position: relative;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              font-size: 1em;
              color: white;
              padding: 0.5rem 1rem;
              cursor: pointer;
              margin-top:15px;
            }
            .desscription{
              margin-top:15px;
              text-align: center;
              font-size: 12px;
              color:#000;
            }
          }
        }
      }
    }
  }
}
.endeavour-hotel-area {
  height:100%;
  width:100%;
  position:absolute;
  background-size: cover;
  position: relative;
  .content {
    height:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding-left: 200px;
    .empry{
      flex:1px;
      height:50%;
      border-top:3px solid #be3437;
      border-bottom:3px solid #be3437;
      .calendar{
        position: absolute;
        top:20px;
        display: flex;
        flex-direction: row;
        margin-left: 10px;
        padding: 10px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        .calendar-text{
          color:white;
          font-size: 24px;
          margin-left: 10px;
        }
      }
    }
    .empry-m{
      flex:1px;
      height:80%;
      border-top:3px solid #be3437;
      border-bottom:3px solid #be3437;
    }
    .left-section {
      background:rgba(0, 0, 0, 0.5);
      backdrop-filter:blur(30px);
      align-items: center;
      justify-content: center;
      display: flex;
      padding-left:30px;
      height : 100%;
      color:white;
      max-width: 400px;
      .logo-img{
        position: absolute;
        top:20px;
        height: 80px;
      }
      
      .left-section-content{
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        height:50%;
        border-left:3px solid #be3437;
        border-right: none;
        .top-border{
          display:flex; 
          flex:1;
          width:100%;
          .border1 {
            width:25%;
            border-top: 3px solid #be3437;
          }
          .border2 {
            flex:1
          }
        }
        .bottom-border{
          flex:1;
          width:100%;
          border-bottom: 3px solid #be3437;
        }
        .title{
          color:white;
          font-size:48px;
          line-height: normal;
          text-align:center;        
        }
        .bottom-line{
          margin-top:30px;
        }
        .desscription{
          color:white;
          margin-top:50px;
          font-size:32px;
          line-height: normal;
          text-align:center;
          padding-left:20px;
          padding-right:20px;          
        }
        .bottom-logo{
          display:flex;
          flex-direction:column;
          align-items:center;
          position:absolute;
          bottom:20px;
        }
      }
    }
    .right-section {
      display: flex;
      flex-direction: column;
      border-radius:16px;
      align-items:center;
      justify-content: center;
      height : 100%;
      flex:1;
      position: relative;
      .calendar{
        position: absolute;
        top:20px;
        left: 10px;
        display: flex;
        flex-direction: row;
        margin-left: 10px;
        padding: 10px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        .calendar-text{
          color:white;
          font-size: 24px;
          margin-left: 10px;
        }
      }
      .time{
        position: absolute;
        top:20px;
        right: 20px;
        display: flex;
        flex-direction: row;
        margin-left: 10px;
        padding: 10px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        .time-text{
          color:white;
          font-size: 24px;
          margin-left: 10px;
        }
      }
      .right-section-border{
        justify-content: center;
        display: flex;
        flex-direction: column;
        height:50%;
        border-top:3px solid #be3437;
        border-bottom:3px solid #be3437;
        padding:20px;
        width: 100%;
        align-items: center;
        color:#540204;
        .right-section-content{
          background:#fbfdfa89;
          backdrop-filter:blur(30px);
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;
          padding:40px;
          border-radius: 16px;
          min-width: 400px;
          .title{
            font-size:24px;
            text-align: center;
          }
          .btn-gradient {
            background:#be3437;
            border: 2px solid transparent;
            &:hover{
              background:#a12628;
            }
          }
          .btn-gradient {
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 1em;
            color: white;
            padding: 0.5rem 1rem;
            cursor: pointer;
            &:hover{
              background:#8a1d1f;
            }
            margin-top:15px;
          }
          .desscription{
            margin-top:15px;
            text-align: center;
          }
        }
      }
    }
    .qrcode-section{
      padding:30px;
      background:white;
      border-radius:10px;
    }

    .left-section-mobile {
      .logo-img{
        position: absolute;
        top:20px;
        height: 50px;
      }
      background:rgba(0, 0, 0, 0.5);
      backdrop-filter:blur(30px);
      align-items: center;
      justify-content: center;
      display: flex;
      padding-left:30px;
      height : 100%;
      color:white;
      max-width: 400px;
      .left-section-content{
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        height:80%;
        border-left:3px solid #be3437;
        border-right: none;
        .top-border{
          display:flex; 
          flex:1;
          width:100%;
          .border1 {
            width:25%;
            border-top: 3px solid #be3437;
          }
          .border2 {
            flex:1
          }
        }
        .bottom-border{
          flex:1;
          width:100%;
          border-bottom: 3px solid #be3437;
        }
        .title{
          color:white;
          font-size:48px;
          line-height: normal;
          text-align:center;        
        }
        .bottom-line{
          margin-top:10px;
          margin-bottom:10px;
        }
        .desscription{
          color:white;
          font-size:30px;
          line-height: normal;
          text-align:center;
          padding-left:20px;
          padding-right:20px;          
        }
        .bottom-logo{
          display:flex;
          flex-direction:column;
          align-items:center;
          position:absolute;
          bottom:20px;
        }
        .right-section {
          padding:20px;
          height:50%;
          margin-top: 20px;
          color:#000;
          .right-section-content{
            background:#fbfdfa89;
            backdrop-filter:blur(30px);
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            padding:30px;
            border-radius: 16px;
            .title{
              font-size:20px;
              text-align: center;
              color:#540204;
            }
            .btn-gradient {
              background:#be3437;
              border: 2px solid transparent;
              &:hover{
                background:#a12628;
              }
            }
            .btn-gradient {
              position: relative;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              font-size: 1em;
              color: white;
              padding: 0.5rem 1rem;
              cursor: pointer;
              &:hover{
                background:#8a1d1f;
              }
              margin-top:15px;
            }
            .desscription{
              margin-top:15px;
              text-align: center;
              font-size: 12px;
              color:#540204;
            }
          }
        }
      }
    }
  }
}