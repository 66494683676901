.home-area {
  position: relative;
  width:100%;
  background: #CDF2E0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .leftPattern{
    position: absolute;
    left: 0px;
    width: 150px;
  }
  .leftBottomPattern{
    position: absolute;
    right: 0px;
    bottom: 55px;
    width: 100px;
  }
  .top-section{
    padding: 20px 20px;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    @media (min-width: 900px) {
      padding: 50px 100px 50px 100px;
    }
    .top-left-logo{
      height: 40px;
    }
    @media (min-width: 500px) {
      .top-left-logo{
        height: 48px;
      } 
    }
    .top-right-logo{
      display: flex;
      flex-direction: row;
      justify-content: center;
      .right-logo-text{
        color:#0E113A;
        margin-left: 10px;
      }
    }
    .top-content{
      padding-top: 50px;
      .title{
        font-size: 48px;
        color: #0E113A;
        line-height: 66px;
        font-weight:500;
      }
      .description{
        font-size: 20px;
        color: #3F3F51;
        line-height: 28px;
        font-weight: 400;
        margin-top: 20px;
      }
      .top-fill-btn{
        background:#3458E0;
        box-shadow: 0 5px 10px 0 rgba(30, 61, 116, 0.25);
        border-radius: 50em;
        border: 2px solid transparent;
        height: 48px;
        color:white;
        margin-top: 20px;
        width: 100%;
        transition: background-color 0.3s, color 0.3s;
        &:hover {
          background-color: #1841d5;
        }
        @media (min-width: 500px) {
          width: 327px;
        }
      }
      .top-outline-btn{
        box-shadow: 0 5px 10px 0 rgba(30, 61, 116, 0.25);
        border-radius: 50em;
        background:transparent;
        border: 2px solid #3458E0;
        height: 48px;
        color:#3458E0;
        width: 100%;
        margin: 20px 0 20px 0;
        transition: background-color 0.3s, color 0.3s;
        &:hover {
          background-color: #ffffff;
        }
        @media (min-width: 500px) {
          width: 327px;
        }
      }
      .top-img{
        width: 100%;
        @media (min-width: 1110px) {
          width: 80%;
        }
      }
    }
  }

  .bottom-section{
    padding: 20px 20px;
    // position: relative;
    background: #CDF2E0;
    flex:1;
    @media (min-width: 900px) {
      padding: 50px 100px 50px 100px;
    }
    .title{
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 40px;
      color:#192670;
      margin-bottom: 30px;
      line-height: 55px;
    }
    .demo{
      cursor: pointer;
      padding: 12px;
      width: 100%;
      border-radius: 8px;
      transition: background-color 0.3s;
      .demo-img-cover{
        overflow: hidden;
        border-radius: 8px;
      }
      &:hover {
        background-color: rgba(128, 187, 230, 0.3);
        .demo-img-cover{
          overflow: hidden;
          border-radius: 8px;
          .demo-img{
            opacity: 0.7;
            transform: scale(1.25);
            border-radius: 8px;
            transition: opacity 0.3s,transform 0.3s;
          }
        }
      }
      .demo-title{
        font-size:18px;
        color:#000000;
        font-weight: 600;
        padding: 4px;
      }
      .description{
        font-size: 14px;
        color: #666666;
        padding: 4px;
      }
      .demo-img{
        width: 100%;
        border-radius: 8px;
        transition: opacity 0.3s, transform 0.3s;
      }
    }
    
  }
  .bottom-logo-line{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 20px 50px 20px;
    background: #1C3648;
    width: 100%;
    position: relative;
    .bottom-logo{
      width: 220px;
      height: 27px;
    }
    color: #EDF3FF;
    font-size: 20px;
  }
}