/*------- 6. Product style  --------*/
.menu-area {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  .table-container{
    width: 70%;
  }
  .css-levciy-MuiTablePagination-displayedRows{
    margin-bottom: 0;
  }
  .css-pdct74-MuiTablePagination-selectLabel{
    margin-bottom: 0;
  }
  .table-row {
    cursor: pointer;
  }
}

